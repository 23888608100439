<template>
<div class="">
    <div class="nk-block-head nk-block-head-sm pb-2">
        <div class="nk-block-between">
            <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">Leads</h3>
                <div class="nk-block-des text-soft">
                    <p>You have total {{leads.length}} leads.</p>
                </div>
            </div><!-- .nk-block-head-content -->
            <div class="nk-block-head-content">
                <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                        <ul class="nk-block-tools g-3">
                            <!-- <li><a href="#" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> -->
                            <li><router-link :to="{name: 'lead'}" class="btn btn-dark text-light"><em class="icon ni ni-plus mr-1"></em>Add</router-link></li>
                        </ul>
                    </div>
                </div><!-- .toggle-wrap -->
            </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
    </div><!-- .nk-block-head -->
    <div class="nk-block">
        <div class="card p-2 card-stretch">
            <div class="card-inner-group">
                <div class="card-inner p-0">
                    <div class="nk-tb-list nk-tb-ulist is-compact">
                          <vue-good-table v-if="leads"
                            :columns="columns"
                            :rows="leads"
                            styleClass="vgt-table condensed tblhov"
                            :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
                                                :sort-options="{ enabled: true,}"
                          >
                          <template slot="table-row" slot-scope="props" >
                            <span class="vgt-muted"  v-if="props.column.field == 'total'" >
                              {{props.formattedRow[props.column.field]}}
                            </span>
                            <div v-else-if="props.column.field == 'actions'">
                              <a-popconfirm
                                  title="Are you sure delete?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteLead(props)"
                                  @cancel="cancel"
                              >
                                  <button class="btn btn-sm mr-1 text-danger"><em class="icon ni ni-trash"/></button>
                              </a-popconfirm>
                            </div>
                            <div v-else @click="goToLead(props)">
                              {{props.formattedRow[props.column.field]}}
                            </div>
                          </template>
                          </vue-good-table>
                        </div><!-- .nk-tb-list -->
                    </div><!-- .card-inner -->
                    
                </div><!-- .card-inner-group -->
            </div><!-- .card -->
        </div><!-- .nk-block -->
    </div>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
        columns: [
          {
            label: 'Lead First Name',
            align: 'start',
            sortable: false,
            field: 'firstName',
          },
          { label: 'Last Name', field: 'lastName'},
          { label: 'Company', field: 'companyName' },
          { label: 'Mobile', field: 'mobile' },
          { label: 'Phone', field: 'phoneNumber' },
          { label: 'Email', field: 'email' },
          { label: 'Status', field: 'statusName' },
          { label: 'Actions', field: 'actions', thClass: 'text-center', tdClass: 'text-center' }
        ],
        leads: [],
        statuses: [],
      }
    },
    created() {
      this.getView()
      this.getLeads()
    },
    methods: {
      getView() {
        this.$http.get('/ViewAccess/leads')
        .then(() => {
        })
        .catch(() => {
        })
    },
      goToLead(params){
        this.$router.push({ name: 'lead', query: { leadId: params.row.id }  })
      },
      getLeads(){
        this.$http.get('/leads/get_Leads')
        .then((response) => {
            this.leads = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      deleteLead(props) {
        this.$http.post('/leads/Delete_Lead', props.row)
        .then(() => {
            this.getLeads()
            this.$message.success('Lead Deleted')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
      }
    }
  }
</script>

<style>

</style>